@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
:root {
    /* Variaveis */
    /*  :: Legenda ::
    ff = font-family
    fs = font-size
    fw = font-weight

*/
    --cor-azul: #005292;
    --cor-azul-escura: #001959;
    --cor-laranja: #ff820b;
    --cor-escura: #333;
    --cor-clara: #fff;
    --cor-fundo: #f4f6fc;
    --cor-texto: #333;
    --cor-texto-claro: #b3b3b3;
    --principal-padding: 30px;
    --font-color: var(--cor-escura);
    --ff-body: 'Roboto', sans-serif;
    --fs-body: .9rem;
    --fs-responsivo: 1.2rem;
    --fc-placeholer: var(--cor-texto-claro);
    --fs-placeholder: 0.8em;
    --fs-h1: 5rem;
    --fc-h1: var(--cor-azul-escura);
    --fs-h2: 1.5rem;
    --fc-h2: var(--cor-azul-escura);
    --fs-h3: 2.75rem;
    --fs-h4: 1.75rem;
    --fw-body: 500;
    --fw-heading: 700;
    --navbar-bg-color: var(--cor-azul-escura);
    --navbar-logo-color: var(--cor-clara);
    --navbar-font-size: .9rem;
    --header-color: var(--cor-clara);
    --link-fg: var(--cor-azul);
    --link-fg-hover: var(--cor-azul-escura);
    --transition: 250ms ease-out;
    --shadow: 2px 5px 20px rgba(0, 0, 0, .2);
    --gradient: linear-gradient(60deg, red, green, blue, yellow);
    --botao-pequeno: .75rem;
    --botao-padrao: 1rem;
    --botao-grande: 1.5rem;
    --bg-botao: #27ae60;
    --bg-botao-claro: #a8a8a8;
    --bg-hover-botao: #449d44;
    --bg-hover-botao-claro: #807878;
    --bg-botao-secundario: #4e71ca;
    --bg-hover-botao-secundario: #2b4588;
}


/* classes Responsivas */

@media screen and (max-width: 1183px) {
    .Navbar {
        font-size: 0.7em!important;

    }
}

@media screen and (max-width: 40em) {
    td {
        font-size: 1rem!important;
    }
    .btn-principal,
    .btn-voltar,
    .btn-secundario{
        margin-top: 1.5rem!important;
        margin-left: 0!important;
        padding: 1rem 1.5rem!important;
        width: 100%;
        font-size: 0.7rem!important;
        float: none!important;
    }
    .container-principal {
        margin: none!important;
        margin-top: 0px!important;
        /* padding-left: 0px!important;
        padding-right: 0px!important;
        padding-top: 0px!important; */
    }
    .tab-content {
        padding: 1rem 1rem 0rem 1rem;
    }
    .col-lg {
        padding-top: 2rem;
    }
    .col {
        margin-top: 2em!important;
    }
    .Navbar {
        font-size: 1.2rem!important;
        font-size: var(--fs-responsivo)!important;

    }
    .nav-pills {
        border-radius: 0!important;
        margin-top: 5rem!important;
    }
    .centralajuda {
        display: none;
    }
    .row {
        display: inline!important;
        display: initial!important;
    }
    .form-group {
         margin: 0px!important;
    }
    .titulo-pagina {
        font-size: 1.2rem!important;
        padding-bottom: 1rem!important;
    }
}

.nav-pills {
    border-radius: 5px;
}


/* Global */

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

.full-size {
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
    position: fixed;
}


/* Tipos Globais */

html,
body {
    -webkit-font-smoothing: antialiased;
    background-color: #f4f6fc;
    background-color: var(--cor-fundo);
    font-family: 'Roboto', sans-serif;
    font-family: var(--ff-body);
}

h1 {
    font-size: 5rem;
    font-size: var(--fs-h1);
    font-family: 'Roboto', sans-serif;
    font-family: var(--ff-body);
    color: #001959;
    color: var(--fc-h1);
}

h2 {
    font-size: 1.5rem;
    font-size: var(--fs-h2);
    font-family: 'Roboto', sans-serif;
    font-family: var(--ff-body);
    color: #001959;
    color: var(--fc-h2);
}
h3 {
    font-size: 1.2rem;
    font-family: 'Roboto', sans-serif;
    font-family: var(--ff-body);
    color: #001959;
    color: var(--fc-h2);
}

input {
    font-size: .9rem;
    font-size: var(--fs-body);
    font-family: 'Roboto', sans-serif;
    font-family: var(--ff-body);
}

select {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

 ::-webkit-input-placeholder {
    font-size: 0.8em;
    font-size: var(--fs-placeholder);
    color: #b3b3b3!important;
    color: var(--fc-placeholer)!important;
}

 ::-moz-placeholder {
    font-size: 0.8em;
    font-size: var(--fs-placeholder);
    color: #b3b3b3!important;
    color: var(--fc-placeholer)!important;
}

 :-ms-input-placeholder {
    font-size: 0.8em;
    font-size: var(--fs-placeholder);
    color: #b3b3b3!important;
    color: var(--fc-placeholer)!important;
}

 ::-ms-input-placeholder {
    font-size: 0.8em;
    font-size: var(--fs-placeholder);
    color: #b3b3b3!important;
    color: var(--fc-placeholer)!important;
}

 ::placeholder {
    font-size: 0.8em;
    font-size: var(--fs-placeholder);
    color: #b3b3b3!important;
    color: var(--fc-placeholer)!important;
}

label {
    font-size: .9rem;
    font-size: var(--fs-body);
    font-family: 'Roboto', sans-serif;
    font-family: var(--ff-body);
    margin-bottom: .6rem !important;
}
Field {
    font-size: .9rem;
    font-size: var(--fs-body);
    font-family: 'Roboto', sans-serif;
    font-family: var(--ff-body);
    margin-top: .6rem !important;
    margin-bottom: .6rem !important;
}

input[type=checkbox] {
    font-size: 1.5em;
    width: 30px;
    position: relative;
    margin: 10px;
    height: 20px;
    border-radius: 4px;
}

svg:not(:root).svg-inline--fa {
    font-size: 1.3em;
}

#root {
    height: 100%;
}
.rodape {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}
.container-rodape {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.rodape p {
    color: #454f6b;
    font-size: 0.8em;
    line-height: 1.4em;
}

.icone {
    margin-right: 0.8em;
    height: 0.8em;
}

.icone-menu {
    margin-right: 0.3em;
    height: 0.8em;
}
.obrigatorio {
    color: red;
}

/* Botões */

.btn-principal {
    background-color: #27ae60 !important;
    background-color: var(--bg-botao) !important;
    color: #fff !important;
    color: var(--cor-clara) !important;
    font-size: .9rem !important;
    font-size: var(--fs-body) !important;
    border: 0px !important;
    padding: 10px 20px;
    float: right;
    margin-left: 1rem;
}

.btn-principal:hover {
    background-color: #449d44 !important;
    background-color: var(--bg-hover-botao) !important;
    color: #fff !important;
}

.btn-secundario {
    background-color: #4e71ca;
    background-color: var(--bg-botao-secundario);
    color: #fff;
    color: var(--cor-clara);
    font-size: .9rem !important;
    font-size: var(--fs-body) !important;
    border: 0px !important;
    margin-right: 1rem !important;
    margin-left: 1rem!;
    padding: 10px 20px;
    float: right;
}

.btn-secundario:hover {
    background-color: #2b4588;
    background-color: var(--bg-hover-botao-secundario);
    color: #fff;
    color: var(--cor-clara);
}

.btn-cadastrar-listagem {
    color: #27ae60;
    font-size: 0.8rem;    
}

.btn-cadastrar-listagem:hover {
    color: #333;
    color: var(--cor-escura);
    font-size: 0.8rem;
}

.btn-editar {
    background-color: #4e71ca;
    background-color: var(--bg-botao-secundario);
    color: #fff;
    color: var(--cor-clara);
}

.btn-editar:hover {
    background-color: #2b4588;
    background-color: var(--bg-hover-botao-secundario);
    color: #fff;
    color: var(--cor-clara);
}

.btn-editar-listagem {
    color: #005292;
    color: var(--cor-azul);
    font-size: 0.8rem;
}

.btn-editar-listagem:hover {
    color: #333;
    color: var(--cor-escura);
    font-size: 0.8rem;
}

.btn-apagar-listagem {
    color: #e74c3c!important;
    font-size: 0.8rem;
}

.btn-apagar-listagem:hover {
    color: #333;
    color: var(--cor-escura);
    font-size: 0.8rem;
}

.btn-excluir {
    background-color: #e74c3c !important;
    color: white !important;
}

.btn-excluir:hover {
    background-color: #c93d2e !important;
    color: white !important;
}

.btn-fechar {
    background-color: #a8a8a8 !important;
    background-color: var(--bg-botao-claro) !important;
    color: white !important;
    font-size: .9rem !important;
    font-size: var(--fs-body) !important;
    border: 0px !important;
    margin-right: 10px !important;
    padding: 10px !important;
}

.btn-fechar:hover {
    background-color: #807878 !important;
    background-color: var(--bg-hover-botao-claro) !important;
}

.btn-voltar {
    background-color: #a8a8a8;
    background-color: var(--bg-botao-claro);
    color: white;
    font-size: .9rem;
    font-size: var(--fs-body);
    border: 0px;
    margin-right: 0;
    margin-left: 20px;
    padding: 10px 20px;
    float: right;
}

.btn-voltar:hover {
    background-color: #807878 !important;
    background-color: var(--bg-hover-botao-claro) !important;
    color: #fff;
}

.btn-salvar {
    color: #fff;
    background: #27ae60;
    background: var(--bg-botao);
    border: 0;
    font-size: 16px;
    line-height: 30px;
    margin: 17px 0px;
    padding: 10px 20px;
    width: 100%;
    
}

.btn-salvar:hover {
    background-color: #449d44 !important;
    background-color: var(--bg-hover-botao) !important;
    color: #fff;
}


/* Alerts */

.alerta {    
    margin-top: 15px; 
}
.alert {
    padding: 10px 0px 10px 10px;
    margin: 0px;
    margin-top: 15px;
    width: 100%;
    border: 1px solid transparent;
    border-radius: .30rem;
    font-size: 12px;
}
.alert-danger {
    color: #ffffff;
    background-color: #de1f30;
}
.alert-success {
    margin-top: 50px;
    font-size: 22px;
    text-align: center;
    width: 100%;
}


/* Menu - Navbar */

.Navbar {
    background-color: #082044;
    color: #fff;
    color: var(--cor-clara);
    font-size: .9rem;
    font-size: var(--navbar-font-size);
    z-index: 10;
}

.navbar-dark .navbar-nav .nav-link {
    color: #b8c1c8;
}

.navbar-nav a:hover{
    text-decoration: none!important;
}

.dropdown-item {
    color: #7f878e;
}

.dropdown-menu {
    margin: 0rem 20rem 0rem -5rem;
}
.dropdown-usuario {
    display: inline-block;
}

@media only screen and (max-width: 990px) {
    .dropdown-menu {
        margin: .125rem 0rem 0rem;
    }
}

.nav-pills .nav-link.active {
    border: 0px;
}
.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
    color: #fff;
}
.container-calendario {
    margin-top: 15px !important;
    margin-left: 30px !important;
    margin-right: 30px !important;
    margin-bottom: 30px !important;
    padding-top: 0px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 20px;
    background-color: #fff;
    border-radius: 7px;
    font-size: .9rem;
    font-size: var(--fs-body);
    color: #333;
    color: var(--cor-texto);
}

/* Páginas - Container Principal */

.container-principal {
    margin-top: 15px !important;
    margin-left: 30px !important;
    margin-right: 30px !important;
    margin-bottom: 30px !important;
    padding-top: 20px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 20px;
    background-color: #fff;
    border-radius: 7px;
    font-size: .9rem;
    font-size: var(--fs-body);
    color: #333;
    color: var(--cor-texto);
}

.container-notificacao {
    margin-left: 30px !important;
    margin-right: 30px !important;
    margin-bottom: 30px !important;
    padding-top: 30px;
    padding-bottom: 30px;
    ;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 7px;
    font-size: .9rem;
    font-size: var(--fs-body);
    color: #fff;
    color: var(--cor-clara);
    background-color: #2b4588;
    background-color: var(--bg-hover-botao-secundario);
    width: 100% !important;
    max-width: auto !important;
}


/* Páginas - Login */

#bg {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -10;
    margin: 0px !important;
    text-align: center;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    background-color: #001959!important;
    background-color: var(--cor-azul-escura)!important;
}

.login {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -150px 0 0 -150px;
    width: 300px;
    height: 300px;
    background-color: #001959!important;
    background-color: var(--cor-azul-escura)!important;
}

.input-login {
    width: 100%;
    margin-bottom: 10px;
    background: rgba(0, 0, 0, 0.3);
    border: none;
    outline: none;
    padding: 10px;
    font-size: 1em;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    box-shadow: inset 0 -5px 45px rgba(100, 100, 100, 0.2), 0 1px 1px rgba(255, 255, 255, 0.2);
    -webkit-transition: box-shadow .5s ease;
    transition: box-shadow .5s ease;
}

.btn-login {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    padding: 4px 10px 4px;
    margin-bottom: 0;
    font-size: 13px;
    line-height: 18px;
    color: #333333;
    text-align: center;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
    vertical-align: middle;
    background-color: #f5f5f5;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#e6e6e6));
    background-image: linear-gradient(top, #ffffff, #e6e6e6);
    background-repeat: repeat-x;
    -webkit-filter: progid: dximagetransform.microsoft.gradient(startColorstr=#ffffff, endColorstr=#e6e6e6, GradientType=0);
            filter: progid: dximagetransform.microsoft.gradient(startColorstr=#ffffff, endColorstr=#e6e6e6, GradientType=0);
    border-color: #e6e6e6 #e6e6e6 #e6e6e6;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    *margin-left: .3em;
}

.loginNews {
    background-size: cover;
    background-blend-mode: multiply;
    position: relative;
    padding: 15em;
    padding-top: 20em;
    color: #fff;
}

.loginNews p {
    font-size: 16px;
    padding-bottom: 2em;
}

.loginNewsIcone {
    font-size: 54px;
}

.loginNewsTitulo {
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    padding-bottom: 1em;
    text-align: center;
    line-height: 60px;
    font-weight: 800;
}

.LoginNewsBotao {
    background-color: #007bff;
    font-size: 1.2em;
}


/* Páginas - Card */

.card {
    margin-top: 10px;
    margin-bottom: 30px;
    margin-right: 30px;
    background-color: #fff;
    border: 1px solid #F1F4F9;
    border-radius: 7px;
}

.card-header {
    background-color: #fff;
    border: none;
    padding-top: 2em;
}

.card-column {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.cardFooter {
    flex:1 1;
}

/* Páginas - Título */

.titulo-pagina {
    font-size: 1.5rem;
    font-size: var(--fs-h2);
    color: #333;
    color: var(--cor-texto);
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 1rem;
    font-weight: bold;
}

.titulo-pagina-principal {
    font-size: 1.5rem;
    font-size: var(--fs-h2);
    color: #005292;
    color: var(--cor-azul);
    font-weight: bold;
    margin-top: 1em;
    padding-right: 2rem;
    padding-left: 2rem;
}


/* Páginas - Breadcrumb */

.breadcrumb {
    background-color: #f4f6fc;
    margin: 0px;
    font-size: 1.3vh;
    margin-top: 2rem;
}

.breadcrumb a {
    color: #005292;
    color: var(--link-fg);
}


/* Páginas - Toolbar */

.toolbar {
    margin-left: 3px;
    margin-top: 25px;
    margin-bottom: 5px;
}

.toolbar button {
    margin-right: 10px;
}


/* Listagem */
.pesquisa {
    margin-bottom: 20px!important;
}
.lista {
    border: 0px;
    background-color: none!important;
    border: none!important;
}

.lista-tabela {
    border: 0px;
    background-color: none!important;
    border: none!important;
    border-radius: 7px;
}
.p-paginator {
    border: 0px!important;
    border-radius: 0px 10px 10px 0px;
}
.p-paginator-icon {
    color: #949494;
}
.p-dropdown .p-dropdown-trigger {
    background-color: #d3d3d3!important;
}
.p-dropdown .p-dropdown-label {
    background-color: #d3d3d3!important;
    color: #949494!important;
}
.p-datatable .p-sortable-column.p-highlight {
    color: #000!important;
}
.p-sortable-column-icon {
    color: #cecece!important;
    padding-left: 10px;
}

th {
    color: #333;
    color: var(--cor-texto) ;
    background-color: #fff!important;
    border: 0px!important;
    border-bottom: 1px solid #e8e8e8!important;
    font-size: 1em!important;
    text-align: left!important;
    font-weight: bold!important;
    z-index: 0;
}

td {
    color: #333 !important;
    color: var(--cor-texto) !important;
    border: none !important;
    text-align: left !important;
    font-size: 1.2em;
}

td:first-child {
    border-radius: 10px 0px 0px 10px!important;
}

td:last-child {
    border-radius: 0px 10px 10px 0px!important;
}


/* Notificações */

.notificacao {
    margin-top: 0px;
    margin-left: 30px;
    margin-right: 30px;
}
.notificacao-filete {
    margin-top: 4em;
    background: rgb(206, 21, 21);
    padding: 20px 0;
}
.notificacao-filete a{
    text-align: center;
    font-size: 14px;
    line-height: 100%;
    color: #FFF;
}
.notificacao-filete a:hover{
    color: #fff;
}


/* Modal */

.modal-margin {
    margin-left:20px;
    margin-right: 20px;
}

.form-group {
    color: #7A7A7F;
    padding: 10px 20px;
    margin: 10px;
}

.modal-footer {
    background-color: #F1F1F1;
}

#app {
  height: 100%;
}
